import React, { useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { dataTable } from "variables/general";

// TODO:
// Set a logic that will check if the user has a subscription
// If the user does not have a subscription, then show the StripePricingTable
// If the user does have a subscription, then show the customer portal

// customer portal: https://stripe.com/docs/customer-management

const StripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
    document.body.removeChild(script);
    };
    }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": "prctbl_1Nkga6InONYFHcfDoMPv8odM",
    "publishable-key": "pk_test_51MrahtInONYFHcfDOls873IByoniLzGWBY1T5veCqAzGPD0LkVnoJFXAim6fPjHPRjLQgwc9083gqRJNaDhurrp800idRIv1Kt",
    // TODO: https://stripe.com/docs/payments/checkout/pricing-table
    // "client-reference-id": ""
  });
};

function Billing() {
  return (
    <>
      <SimpleHeader name="Billing" parentName="Settings" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="border-0">
              <StripePricingTable />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Billing;



// export default StripePricingTable; 