import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { dataTable } from "variables/general";

const CheckButtonFormatter = () => {
  return (
    <div className="custom-control custom-checkbox mb-3">
      <input
        className="custom-control-input"
        id="customCheck1"
        type="checkbox"
      />
      <label
        className="custom-control-label"
        htmlFor="customCheck1"
      >
      </label>
    </div>
  );
};

const FileControlButtonFormatter = () => {
  return (
    <div>
      <a
        className="table-action table-action-download"
        href="#pablo"
        id="tooltip12475021"
        onClick={(e) => e.preventDefault()}
      >
        <i class="fas fa-file-download" />
      </a>
      <UncontrolledTooltip delay={0} target="tooltip12475021">
        Download File
      </UncontrolledTooltip>
      <a
        className="table-action"
        href="#pablo"
        id="tooltip209424781"
        onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-user-edit" />
      </a>
      <UncontrolledTooltip delay={0} target="tooltip209424781">
        Rename File
      </UncontrolledTooltip>
      <a
        className="table-action table-action-move"
        href="#pablo"
        id="tooltip12475022"
        onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-file" />
      </a>
      <UncontrolledTooltip delay={0} target="tooltip12475022">
        Move File
      </UncontrolledTooltip>
      <a
        className="table-action table-action-delete"
        href="#pablo"
        id="tooltip12475020"
        onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-trash" />
      </a>
      <UncontrolledTooltip delay={0} target="tooltip12475020">
        Delete File
      </UncontrolledTooltip>
    </div>
  );
};

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function ListFileTable() {
  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  return (
    <>
      {alert}
      <SimpleHeader name="Workspace" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={dataTable}
                keyField="name"
                columns={[
                  {
                    dataField: "checkbox",
                    formatter: CheckButtonFormatter,
                  },
                  {
                    dataField: "name",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "length",
                    text: "length",
                    sort: true,
                  },
                  {
                    dataField: "created",
                    text: "Created",
                    sort: true,
                  },
                  {
                    dataField: "modified",
                    text: "Modified",
                    sort: true,
                  },
                  {
                    dataField: "fileControlButtons",
                    formatter: FileControlButtonFormatter,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ListFileTable;
